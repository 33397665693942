var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from 'axios';
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDebounce } from 'use-debounce';
import { FormatParam } from '@src/app/helper';
var baseApiUrl = process.env.NEXUS_API_URL;
export var getProfile = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/address/").concat(id, "/profiles"), {})];
            case 1:
                response = (_a.sent()).data;
                return [2 /*return*/, response];
        }
    });
}); };
export var deleteAddress = function (id, selectedid) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.delete("".concat(baseApiUrl, "/talents/").concat(id, "/addresses/").concat(selectedid), {})];
            case 1:
                response = (_a.sent()).data;
                toast.success('Alamat berhasil dihapus');
                return [2 /*return*/, response];
        }
    });
}); };
export var deleteBankAccount = function (id, selectedid) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.delete("".concat(baseApiUrl, "/talents/").concat(id, "/banks/").concat(selectedid), {})];
            case 1:
                response = (_a.sent()).data;
                toast.success('No Rekening berhasil dihapus');
                return [2 /*return*/, response];
        }
    });
}); };
//categories
export function fetchCategories(param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var queryString, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/talent-categories?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function fetchCategoryById(id) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/talents/").concat(id, "/categories"))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function useCategoryById(id) {
    return useQuery(['categories', id], function () { return fetchCategoryById(id); });
}
export function useCategories(param) {
    var _a = useQuery(['categories'], function () { return fetchCategories(param); }), categories = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        categories: categories,
        isLoading: isLoading,
        error: error,
    };
}
//profile start here
export function fetchProfile(param) {
    return __awaiter(this, void 0, void 0, function () {
        var queryString, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/talents/profiles?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data.data];
            }
        });
    });
}
function fetchProfileById(_a) {
    var _b = _a === void 0 ? {} : _a, id = _b.id, pubId = _b.pubId;
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    url = "".concat(baseApiUrl, "/talents/").concat(id, "/profiles/").concat(pubId);
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_c.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function fetchProfileHistory(id) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(baseApiUrl, "/talents/profiles/").concat(id, "/histories");
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
// Create a new profiles
function createProfile(profiles) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = profiles.id, values = __rest(profiles, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/talents/").concat(id, "/profiles/"), [values])];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Update an existing profiles
function updateProfile(address) {
    return __awaiter(this, void 0, void 0, function () {
        var pubId, id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pubId = address.pubId, id = address.id, values = __rest(address, ["pubId", "id"]);
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/talents/").concat(id, "/profiles/").concat(pubId), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
//delete new profile
function deleteProfile(profiles) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = profiles.id, values = __rest(profiles, ["id"]);
                    return [4 /*yield*/, axios.delete("".concat(baseApiUrl, "/talents/").concat(id, "/profiles/").concat(profiles.pubId))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
//share profile
function shareProfile(profiles) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = profiles.id, values = __rest(profiles, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/talents/profiles/").concat(id, "/share"), {
                            shares: [
                                {
                                    divisionPubId: values.divisionPubId,
                                    teamPubId: values.teamPubId,
                                },
                            ],
                        })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Get a single bankAccount as a react query function
export function useProfileById(id, pubId, queryOptions) {
    return useQuery(['profiles', id, pubId], function () { return fetchProfileById({ id: id, pubId: pubId }); }, queryOptions);
}
export function useProfileHistory(id, enabled) {
    return useQuery(['profileHistory', id], function () { return fetchProfileHistory(id); }, {
        enabled: enabled,
    });
}
export function useProfile(id, enabled, param) {
    var queryClient = useQueryClient();
    var debouncedFilter = useDebounce(param, 500)[0];
    var _a = useQuery(['profiles', debouncedFilter], function () { return fetchProfile(param); }, {
        enabled: enabled,
    }), profiles = _a.data, isLoading = _a.isLoading, error = _a.error, refetch = _a.refetch;
    var createProfileMutation = useMutation(createProfile, {
        onSuccess: function (newProfile) {
            queryClient.setQueryData('profiles', function (oldProfiles) {
                if (oldProfiles === void 0) { oldProfiles = []; }
                return __spreadArray(__spreadArray([], oldProfiles, true), [
                    newProfile,
                ], false);
            });
            toast.success('Profil berhasil dibuat');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Profil gagal dibuat: ".concat(error.message));
        },
    });
    var updateProfileMutation = useMutation(updateProfile, {
        onSuccess: function (updatedProfile) {
            queryClient.setQueryData('profiles', function (oldProfiles) {
                if (oldProfiles === void 0) { oldProfiles = []; }
                return oldProfiles.map(function (profile) {
                    return profile.pubId === updatedProfile.pubId ? updatedProfile : profile;
                });
            });
            toast.success('Profile berhasil diupdate');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Profile gagal diupdate: ".concat(error.message));
        },
    });
    var deleteProfileMutation = useMutation(deleteProfile, {
        onSuccess: function (newProfile) {
            queryClient.setQueryData('profiles', function (oldProfiles) {
                if (oldProfiles === void 0) { oldProfiles = []; }
                return __spreadArray(__spreadArray([], oldProfiles, true), [
                    newProfile,
                ], false);
            });
            toast.success('Profil berhasil dihapus');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Profil gagal dihapus: ".concat(error.message));
        },
    });
    var shareProfileMutation = useMutation(shareProfile, {
        onSuccess: function (newProfile) {
            queryClient.setQueryData('profiles', function (oldProfiles) {
                if (oldProfiles === void 0) { oldProfiles = []; }
                return __spreadArray(__spreadArray([], oldProfiles, true), [
                    newProfile,
                ], false);
            });
            toast.success('Profil berhasil dibagikan');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Profil gagal dibagikan: ".concat(error.message));
        },
    });
    return {
        profiles: profiles,
        isLoading: isLoading,
        error: error,
        refetch: refetch,
        createProfile: createProfileMutation.mutateAsync,
        updateProfile: updateProfileMutation.mutateAsync,
        deleteProfile: deleteProfileMutation.mutateAsync,
        shareProfile: shareProfileMutation.mutateAsync,
    };
}
//address start from here
function fetchAddress(id, param) {
    return __awaiter(this, void 0, void 0, function () {
        var queryString, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    if (!(id !== undefined)) return [3 /*break*/, 2];
                    return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/talents/addresses?").concat(queryString))];
                case 1:
                    res = (_a.sent()).data;
                    return [2 /*return*/, res.data.data];
                case 2: return [2 /*return*/, []];
            }
        });
    });
}
function fetchTalentAddresses(talentPubId, query) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/talents/").concat(talentPubId, "/addresses?").concat(FormatParam(query)))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data.data];
            }
        });
    });
}
function fetchAddressById(_a) {
    var _b = _a === void 0 ? {} : _a, id = _b.id, pubId = _b.pubId;
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    url = "".concat(baseApiUrl, "/talents/").concat(id, "/addresses/").concat(pubId);
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_c.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
// Create a new address
function createAddress(address) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = address.id, values = __rest(address, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/talents/").concat(id, "/addresses/"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Update an existing address
function updateAddress(address) {
    return __awaiter(this, void 0, void 0, function () {
        var pubId, id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pubId = address.pubId, id = address.id, values = __rest(address, ["pubId", "id"]);
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/talents/").concat(id, "/addresses/").concat(pubId), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Get a single address as a react query function
export function useAddressById(id, pubId, queryOptions) {
    return useQuery(['address', id, pubId], function () { return fetchAddressById({ id: id, pubId: pubId }); }, queryOptions);
}
export function useAddresses(id, enabled, param) {
    var queryClient = useQueryClient();
    var debouncedFilter = useDebounce(param, 500)[0];
    var _a = useQuery(['address', debouncedFilter], function () { return fetchAddress(id, param); }, {
        enabled: enabled,
    }), addresses = _a.data, isLoading = _a.isLoading, error = _a.error, refetch = _a.refetch;
    var createAddressMutation = useMutation(createAddress, {
        onSuccess: function (newAddress) {
            queryClient.setQueryData('address', function (oldAddresss) {
                if (oldAddresss === void 0) { oldAddresss = []; }
                return __spreadArray(__spreadArray([], oldAddresss, true), [
                    newAddress,
                ], false);
            });
            toast.success('Address created successfully');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Failed to create address: ".concat(error.message));
        },
    });
    var updateAddressMutation = useMutation(updateAddress, {
        onSuccess: function (updatedAddress) {
            queryClient.setQueryData('address', function (oldAddresss) {
                if (oldAddresss === void 0) { oldAddresss = []; }
                return oldAddresss.map(function (address) {
                    return address.pubId === updatedAddress.pubId ? updatedAddress : address;
                });
            });
            toast.success('Alamat berhasil diupdate');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Alamat gagal diupdate: ".concat(error.message));
        },
    });
    return {
        addresses: addresses,
        isLoading: isLoading,
        error: error,
        refetch: refetch,
        createAddress: createAddressMutation.mutateAsync,
        updateAddress: updateAddressMutation.mutateAsync,
    };
}
export function useTalentAddresses(talentPubId, query) {
    var _a = useQuery(['talents', talentPubId, 'addresses'], function () { return fetchTalentAddresses(talentPubId, query); }, {
        enabled: !!talentPubId,
    }), data = _a.data, others = __rest(_a, ["data"]);
    return __assign({ addresses: data }, others);
}
//bankaccount start from here
function fetchBankAccount(id, param) {
    return __awaiter(this, void 0, void 0, function () {
        var queryString, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    if (!(id !== undefined)) return [3 /*break*/, 2];
                    return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/talents/banks?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data.data];
                case 2: return [2 /*return*/, []];
            }
        });
    });
}
function fetchTalentBankAccounts(talentPubId, query) {
    if (query === void 0) { query = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/talents/").concat(talentPubId, "/banks?").concat(FormatParam(query)))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data.data];
            }
        });
    });
}
function fetchBankAccountById(_a) {
    var _b = _a === void 0 ? {} : _a, id = _b.id, bankId = _b.bankId;
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    url = "".concat(baseApiUrl, "/talents/").concat(id, "/banks/").concat(bankId);
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_c.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
// Create a new bankAccount
function createBankAccount(bankAccount) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = bankAccount.id, values = __rest(bankAccount, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/talents/").concat(id, "/banks/"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Update an existing bankAccount
function updateBankAccount(bankAccount) {
    return __awaiter(this, void 0, void 0, function () {
        var pubId, id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pubId = bankAccount.pubId, id = bankAccount.id, values = __rest(bankAccount, ["pubId", "id"]);
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/talents/").concat(id, "/banks/").concat(pubId), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Get a single bankAccount as a react query function
export function useBankAccountById(id, bankId) {
    return useQuery(['bankAccount', id, bankId], function () { return fetchBankAccountById({ id: id, bankId: bankId }); });
}
export function useBankAccounts(id, enabled, param) {
    var queryClient = useQueryClient();
    var debouncedFilter = useDebounce(param, 500)[0];
    var _a = useQuery(['bankAccount', debouncedFilter], function () { return fetchBankAccount(id, param); }, {
        enabled: enabled,
    }), bankAccounts = _a.data, isLoading = _a.isLoading, error = _a.error, refetch = _a.refetch;
    var createBankAccountMutation = useMutation(createBankAccount, {
        onSuccess: function (newBankAccount) {
            queryClient.setQueryData('bankAccount', function (oldBankAccounts) {
                if (oldBankAccounts === void 0) { oldBankAccounts = []; }
                return __spreadArray(__spreadArray([], oldBankAccounts, true), [
                    newBankAccount,
                ], false);
            });
            toast.success('No rekening berhasil dibuat');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("No rekening gagal dibuat: ".concat(error.message));
        },
    });
    var updateBankAccountMutation = useMutation(updateBankAccount, {
        onSuccess: function (updatedBankAccount) {
            queryClient.setQueryData('bankAccount', function (oldBankAccounts) {
                if (oldBankAccounts === void 0) { oldBankAccounts = []; }
                return oldBankAccounts.map(function (bankAccount) {
                    return bankAccount.bankPubId === updatedBankAccount.bankPubId ? updatedBankAccount : bankAccount;
                });
            });
            toast.success('No rekening berhasil diupdate');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("No rekening gagal diupdate: ".concat(error.message));
        },
    });
    return {
        bankAccounts: bankAccounts,
        isLoading: isLoading,
        error: error,
        refetch: refetch,
        createBankAccount: createBankAccountMutation.mutateAsync,
        updateBankAccount: updateBankAccountMutation.mutateAsync,
    };
}
export function useTalentBankAccounts(talentPubId, query) {
    var _a = useQuery(['talents', talentPubId, 'banks'], function () { return fetchTalentBankAccounts(talentPubId, query); }, {
        enabled: !!talentPubId,
    }), data = _a.data, others = __rest(_a, ["data"]);
    return __assign({ bankAccounts: data }, others);
}
//blacklist-unblacklist start from here
function fetchBlacklistHistory(id, param) {
    return __awaiter(this, void 0, void 0, function () {
        var url, queryString, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(baseApiUrl, "/talents/").concat(id, "/blacklist");
                    queryString = FormatParam(param);
                    return [4 /*yield*/, axios.get("".concat(url, "?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data.data];
            }
        });
    });
}
export function useBlacklistHistory(id, enabled, param) {
    return useQuery(['blacklistHistory', id], function () { return fetchBlacklistHistory(id, param); }, {
        enabled: enabled,
    });
}
// blacklist
function blacklist(param) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = param.id, values = __rest(param, ["id"]);
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/talents/").concat(id, "/blacklist"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// unblacklist
function unblacklist(param) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = param.id, values = __rest(param, ["id"]);
                    values.note = ' ';
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/talents/").concat(id, "/unblacklist"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useBlacklists(id) {
    var queryClient = useQueryClient();
    var blacklistMutation = useMutation(blacklist, {
        onSuccess: function (newTalent) {
            queryClient.setQueryData('talent', function (oldTalent) {
                if (oldTalent === void 0) { oldTalent = []; }
                return __spreadArray(__spreadArray([], oldTalent, true), [
                    newTalent,
                ], false);
            });
            toast.success('Talent berhasil diblacklist');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Talent gagal diblacklist: ".concat(error.message));
        },
    });
    var unblacklistMutation = useMutation(unblacklist, {
        onSuccess: function (newTalent) {
            queryClient.setQueryData('talent', function (oldTalent) {
                if (oldTalent === void 0) { oldTalent = []; }
                return __spreadArray(__spreadArray([], oldTalent, true), [
                    newTalent,
                ], false);
            });
            toast.success('Talent berhasil diunblacklist');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Talent gagal diunblacklist: ".concat(error.message));
        },
    });
    return {
        blacklist: blacklistMutation.mutateAsync,
        unblacklist: unblacklistMutation.mutateAsync,
    };
}
//list-unlist start from here
function fetchListHistory(id, param) {
    return __awaiter(this, void 0, void 0, function () {
        var url, queryString, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(baseApiUrl, "/talents/").concat(id, "/listed");
                    queryString = FormatParam(param);
                    return [4 /*yield*/, axios.get("".concat(url, "?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data.data];
            }
        });
    });
}
export function useListHistory(id, enabled, param) {
    return useQuery(['listHistory', id], function () { return fetchListHistory(id, param); }, {
        enabled: enabled,
    });
}
// list
function list(param) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = param.id, values = __rest(param, ["id"]);
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/talents/").concat(id, "/listed"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// unlist
function unlist(param) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = param.id, values = __rest(param, ["id"]);
                    values.note = '-'; //set note to -
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/talents/").concat(id, "/unlisted"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useLists(id) {
    var queryClient = useQueryClient();
    var listMutation = useMutation(list, {
        onSuccess: function (newTalent) {
            queryClient.setQueryData('talent', function (oldTalent) {
                if (oldTalent === void 0) { oldTalent = []; }
                return __spreadArray(__spreadArray([], oldTalent, true), [
                    newTalent,
                ], false);
            });
            toast.success('Talent berhasil diblacklist');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Talent gagal dilist: ".concat(error.message));
        },
    });
    var unlistMutation = useMutation(unlist, {
        onSuccess: function (newTalent) {
            queryClient.setQueryData('talent', function (oldTalent) {
                if (oldTalent === void 0) { oldTalent = []; }
                return __spreadArray(__spreadArray([], oldTalent, true), [
                    newTalent,
                ], false);
            });
            toast.success('Talent berhasil diunlist');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Talent gagal diunlist: ".concat(error.message));
        },
    });
    return {
        list: listMutation.mutateAsync,
        unlist: unlistMutation.mutateAsync,
    };
}
//attachment start from here
function fetchAttachment(param) {
    return __awaiter(this, void 0, void 0, function () {
        var queryString, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    return [4 /*yield*/, axios.get("".concat(baseApiUrl, "/attachments?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function fetchAttachmentById(id) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(baseApiUrl, "/attachments/").concat(id);
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
// Create a new attachment
function createAttachment(attachment) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = attachment.id, values = __rest(attachment, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/attachments"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Update an existing attachment
function updateAttachment(attachment) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = attachment.id, values = __rest(attachment, ["id"]);
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/attachments/").concat(id), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Get a single attachment as a react query function
export function useAttachmentById(id) {
    return useQuery(['attachment', id], function () { return fetchAttachmentById(id); });
}
export function useAttachments(param, enabled) {
    var queryClient = useQueryClient();
    var debouncedFilter = useDebounce(param, 500)[0];
    var _a = useQuery(['attachment', debouncedFilter], function () { return fetchAttachment(param); }, {
        enabled: enabled,
    }), attachments = _a.data, isLoading = _a.isLoading, error = _a.error, refetch = _a.refetch;
    var createAttachmentMutation = useMutation(createAttachment, {
        onSuccess: function (newAttachment) {
            queryClient.setQueryData('attachment', function (oldAttachments) {
                if (oldAttachments === void 0) { oldAttachments = []; }
                return __spreadArray(__spreadArray([], oldAttachments, true), [
                    newAttachment,
                ], false);
            });
            toast.success('Attachment berhasil dibuat');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Attachment gagal dibuat: ".concat(error.message));
        },
    });
    var updateAttachmentMutation = useMutation(updateAttachment, {
        onSuccess: function (updatedAttachment) {
            queryClient.setQueryData('attachment', function (oldAttachments) {
                if (oldAttachments === void 0) { oldAttachments = []; }
                return oldAttachments.map(function (attachment) {
                    return attachment.id === updatedAttachment.id ? updatedAttachment : attachment;
                });
            });
            toast.success('Attachment berhasil diupdate');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Attachment gagal diupdate: ".concat(error.message));
        },
    });
    return {
        attachments: attachments,
        isLoading: isLoading,
        error: error,
        refetch: refetch,
        createAttachment: createAttachmentMutation.mutateAsync,
        updateAttachment: updateAttachmentMutation.mutateAsync,
    };
}
