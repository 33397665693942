var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard, KTSVG } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { paginationOptions, MultiDimensionalFilter, userInformation, TalentStatusNameList, DefaultAllList, FormatYearMonthDate, DefaultAllMultiList, } from '@src/app/helper';
import Input from '@src/components/Atoms/Input/Input';
import { useTalents } from '../TalentHooks/Talent.hooks';
import Select from '@src/components/Atoms/Select/Select';
import '@src/app/styles/datatable.css';
import TalentFilter from '@src/components/molecules/Talent/Filter/TalentFilter';
import RecordTalent from '@src/components/molecules/Record/Talent';
import RecordSOW from '@src/components/molecules/Record/SOW';
import { useSocialMedia } from '../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useTeams } from '../../TeamPage/TeamHooks/Team.hooks';
import { useDivisions } from '../../DivisionPage/DivisionHooks/Division.hooks';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Loading from '@src/components/Atoms/Loading/Loading';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import { useReligions } from '../../LookupPage/LookupHooks/Lookup.hooks';
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { useJobTypes } from '../../JobTypePage/JobType/JobType.hooks';
import { useLists } from '../TalentHooks/TalentDetail.hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import BlacklistForm from '@src/components/Atoms/Drawer/TalentDetailFormDrawer/BlacklistForm';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Talent',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var handleStatusStyle = function (type) {
    switch (type) {
        case 'NotYet':
            return 'warning';
        case 'OnGoing':
            return 'success';
        case 'Done':
            return 'primary';
        case 'Cancelled':
            return 'danger';
        case 'Rejected':
            return 'secondary';
        default:
            return 'warning';
    }
};
var ListFormSchema = Yup.object().shape({});
var TalentIndex = function (_a) {
    var _b = _a.disableBreadcrumbs, disableBreadcrumbs = _b === void 0 ? false : _b, _c = _a.withActionDetail, withActionDetail = _c === void 0 ? false : _c;
    var navigate = useNavigate();
    var intl = useIntl();
    var id = useParams().id;
    var role = JSON.parse(userInformation).roles[0];
    //initialize filter
    var _d = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
    }), filter = _d[0], setFilter = _d[1];
    var _e = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
    }), filterSearch = _e[0], setFilterSearch = _e[1];
    //getdata
    var _f = useTalents(filter), talents = _f.talents, isLoading = _f.isLoading, deleteTalent = _f.deleteTalent, deleteTalents = _f.deleteTalents, refetch = _f.refetch;
    var socialMedia = useSocialMedia().socialMedia;
    var _g = useTeams(filterSearch), team = _g.team, refetchTeam = _g.refetch;
    var _h = useDivisions(filterSearch), divisions = _h.divisions, refetchDivision = _h.refetch;
    var _j = useState(0), divisionValue = _j[0], setDivisionValue = _j[1];
    var _k = useState(0), teamValue = _k[0], setTeamValue = _k[1];
    var socmedIds = socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.map(function (socmed) { return socmed.pubId; });
    var _l = useLists(id), list = _l.list, unlist = _l.unlist;
    var optionSocialMedia = socialMedia &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    optionSocialMedia &&
        optionSocialMedia.unshift(DefaultAllList);
    var optionDivision = divisions &&
        divisions.map(function (division) { return ({
            name: "".concat(division.name, " - ").concat(division.socialMedia.name),
            value: division.pubId,
        }); });
    var optionTeam = (team === null || team === void 0 ? void 0 : team.data) &&
        team.data.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); });
    optionTeam && optionTeam.unshift(DefaultAllList);
    var religions = useReligions().religions;
    var optionReligions = religions &&
        religions.data &&
        religions.data.map(function (religion) { return ({
            label: religion.name,
            value: religion.pubId,
        }); });
    optionReligions && optionReligions.unshift(DefaultAllMultiList);
    var _m = useState([DefaultAllMultiList]), selectedReligion = _m[0], setSelectedReligion = _m[1];
    var onChangeReligion = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'religion.pubId';
        event.target.value = e == null ? '' : e.value;
        setSelectedReligion(e);
        handleMultiDimensionalFilterChange(event);
    };
    var _o = useState(null), date = _o[0], setDate = _o[1];
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'Talent.dob';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    var ListValues = {
        note: '',
    };
    var _p = useState(''), talentId = _p[0], setTalentId = _p[1];
    var _q = useState(false), isOpenList = _q[0], setIsOpenList = _q[1];
    var _r = useState('Alasan List'), tabName = _r[0], setTabName = _r[1];
    var unlistItem = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            unlist({ id: id });
            return [2 /*return*/];
        });
    }); };
    var listItem = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTalentId(id);
            setIsOpenList(true);
            setTabName('Alasan List');
            return [2 /*return*/];
        });
    }); };
    var ListFormik = useFormik({
        initialValues: ListValues,
        enableReinitialize: true,
        validationSchema: ListFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, e_1, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        if (!tabName.includes('Unlist')) return [3 /*break*/, 2];
                        return [4 /*yield*/, unlist({ id: talentId, note: values.note })];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, list({ id: talentId, note: values.note })];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        _a;
                        setIsOpenList(false);
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _b.sent();
                        error = e_1.response.data;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); },
    });
    //jobtype
    // const [isOptionJobType, setIsOptionJobType] = useState<boolean>(false)
    var _s = useState(true), isJobType = _s[0], setIsJobType = _s[1];
    var _t = useState([]), selectedJobType = _t[0], setSelectedJobType = _t[1];
    var _u = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: "[[\"recordStatus\", \"Active\"]]",
    }), filterJobType = _u[0], setFilterJobType = _u[1];
    var _v = useJobTypes(filterJobType), jobTypes = _v.jobTypes, isLoadingJobType = _v.isLoading, refetchJobType = _v.refetch;
    var optionJobType = jobTypes && jobTypes.data && jobTypes.data.length > 0
        ? jobTypes.data
            // .filter((obj) => socmedIds?.includes(obj.socialMedia.pubId))
            .map(function (job) { return ({
            label: job.name,
            value: job.pubId,
        }); })
        : undefined;
    var onChangeJobType = function (e) {
        setSelectedJobType(e);
        setIsJobType(false);
        var event = { target: { name: '', value: null } };
        event.target.name = 'talent.jobType';
        event.target.value = e == null ? '' : e.value;
        handleMultiDimensionalFilterChange(event);
    };
    //filter
    var _w = useState({
        division: '',
    }), selectedFilter = _w[0], setSelectedFilter = _w[1];
    //pagination
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
        refetch();
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    //search & filters
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        MenuComponent.reinitialization();
    }, []);
    var columns = __spreadArray([
        {
            name: 'Talent',
            cell: function (record) { return (_jsx(RecordTalent, { name: record.name, pubId: record.pubId, cpName: '', cpPhoneNumber: '', cpEmail: '', profiles: record.profiles, onClick: gotoDetail })); },
            sortable: true,
        },
        {
            name: 'Contact Person',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsx("p", __assign({ className: 'mb-1' }, { children: record.cpName })), _jsx("p", __assign({ className: 'mb-1' }, { children: record.cpPhoneNumber })), _jsx("p", __assign({ className: 'mb-1' }, { children: record.cpEmail }))] }));
            },
            sortable: true,
        },
        {
            name: 'Status',
            cell: function (record) {
                var key = Object.keys(TalentStatusNameList).indexOf(record.talentStatus);
                var status = Object.values(TalentStatusNameList)[key];
                return (_jsx("span", __assign({ className: "badge badge-".concat(handleStatusStyle(record.talentStatus)) }, { children: status })));
            },
            sortable: true,
        },
        {
            name: 'List/Unlist',
            width: '130px',
            cell: function (record) {
                return (_jsxs(Fragment, { children: [_jsxs("span", __assign({ className: 'btn btn-light btn-active-light-primary btn-sm', "data-kt-menu-trigger": 'click', "data-kt-menu-placement": 'bottom-end' }, { children: [record.isListed ? 'Listed' : 'Unlisted', _jsx(KTSVG, { path: '/media/icons/duotune/arrows/arr072.svg', className: 'svg-icon-5 m-0' })] })), _jsxs("div", __assign({ className: 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-225px py-4', "data-kt-menu": 'true' }, { children: [record.isListed ? (_jsx(Fragment, { children: _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                                                unlistItem(record.pubId);
                                            } }, { children: "Unlisted" })) })) })) : undefined, !record.isListed ? (_jsx(Fragment, { children: _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                                                listItem(record.pubId);
                                            } }, { children: "Listed" })) })) })) : undefined] })), _jsx("p", { children: _jsx("small", __assign({ className: 'text-muted' }, { children: record.listedOn == 0 && 'Belum List/Unlist' })) })] }));
            },
            sortable: true,
        },
        {
            name: 'SOW Insight',
            cell: function (record) { return (_jsx(RecordSOW, { datas: record })); },
            sortable: true,
            omit: !!isJobType,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record }); },
            sortable: true,
        }
    ], (withActionDetail
        ? [
            {
                name: 'Action',
                cell: function (record) {
                    return (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary mx-2', onClick: function () { return gotoDetail(record.pubId); } }, { children: "Detail" })));
                },
            },
        ]
        : []), true);
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (socialMedia && socialMedia.length > 0) {
            var filterCriteria = [
                'talentTeams.division.socialMedia.pubId',
                optionSocialMedia && optionSocialMedia[0].value,
            ];
            filterValue.push(filterCriteria);
        }
        if (team && team.data && team.data.length > 0) {
            var filterCriteria = ['talentTeams.team.pubId', optionTeam && optionTeam[0].value];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    var _x = useState(0), jobTypeValue = _x[0], setJobTypeValue = _x[1];
    var onChangeSocialMedia = function (e) {
        e.target.name = 'talentTeams.division.socialMedia.pubId';
        onChangeFilter(e);
        setDivisionValue(divisionValue + 1);
        // if (e.target.value == '') {
        //   setIsOptionJobType(false)
        //   setIsJobType(true)
        // } else {
        //   setIsOptionJobType(true)
        // }
        // filterJobType['filterBy'] = `[["socialMedia.pubId", "${e.targselectedFilteret.value}"]]`
        // setJobTypeValue(jobTypeValue + 1)
        // const socialMediaName = optionSocialMedia?.filter((obj) => obj.value == e.target.value).map((socmed: any) => socmed.name)
        setSelectedFilter(__assign(__assign({}, selectedFilter), { division: '' }));
        handleMultiDimensionalFilterChange(e);
    };
    var onChangeDivision = function (e) {
        e.target.name = 'talentTeams.division.pubId';
        onChangeFilter(e);
        setTeamValue(teamValue + 1);
        var divisionName = optionDivision === null || optionDivision === void 0 ? void 0 : optionDivision.filter(function (obj) { return obj.value == e.target.value; }).map(function (div) { return div.name.split('').filter(function (e) { return e.trim().length; }).join('').split('-')[1]; });
        setSelectedFilter(__assign(__assign({}, selectedFilter), { division: divisionName === null || divisionName === void 0 ? void 0 : divisionName.toString() }));
    };
    var onChangeFilter = function (event) {
        var filterValue = filterSearch.filterBy !== '' ? JSON.parse(filterSearch.filterBy) : '';
        if (filterSearch.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilterSearch(__assign(__assign({}, filterSearch), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilterSearch(__assign(__assign({}, filterSearch), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    useEffect(function () {
        refetchDivision();
    }, [divisionValue]);
    useEffect(function () {
        refetchTeam;
    }, [teamValue]);
    useEffect(function () {
        refetchJobType();
    }, [jobTypeValue]);
    return (_jsxs(Fragment, { children: [!disableBreadcrumbs ? (_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Talent" }))) : undefined, _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsxs("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: [_jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                    handleFilterChange(e);
                                                } }) })), _jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: optionSocialMedia && optionSocialMedia.length > 0 && (_jsx(Select, { name: 'talentTeams.division.socialMedia.pubId', onChange: function (e) {
                                                    handleMultiDimensionalFilterChange(e);
                                                    onChangeSocialMedia(e);
                                                }, isPlaceholder: false, optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value })) })), _jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: optionDivision && optionDivision.length > 0 && (_jsx(Select, { name: 'talentTeams.division.pubId', onChange: function (e) {
                                                    handleMultiDimensionalFilterChange(e);
                                                    onChangeDivision(e);
                                                }, placeholder: 'Filter by Division', optionList: optionDivision })) })), _jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: optionTeam && optionTeam.length > 0 && (_jsx(Select, { name: 'talentTeams.team.pubId', onChange: function (e) {
                                                    handleMultiDimensionalFilterChange(e);
                                                }, isPlaceholder: false, optionList: optionTeam, defaultValue: optionTeam[0].value })) })), _jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: !isLoadingJobType && optionJobType && ( //&& isOptionJobType
                                            _jsx(MultiSelectFormik, { name: 'talent.jobType' //jobDetail.jobType.pubId
                                                , label: '', placeholder: 'Filter by JobType', formik: [], isMulti: false, options: optionJobType, value: selectedJobType, onchange: onChangeJobType, isChildField: true })) })), _jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: optionReligions && optionReligions.length > 0 && (_jsx(MultiSelectFormik, { name: 'religion.pubId', label: '', placeholder: 'Filter by Religion', formik: [], isMulti: false, options: optionReligions, value: selectedReligion, onchange: onChangeReligion, isChildField: true })) })), _jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: date, inputFormat: 'DD/MM/YYYY', dayOfWeekFormatter: function (day) { return day; }, onChange: function (e) {
                                                        onChangeDate(e);
                                                    }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { className: 'product-datepicker form-control form-control-solid', style: { width: 200 } }))); } }) })) }))] })), _jsx("div", __assign({ className: 'card-title flex' }, { children: _jsx(TalentFilter, { filterdata: selectedFilter, handlechange: function (e) {
                                            handleMultiDimensionalFilterChange(e);
                                        } }) }))] })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: talents ? talents.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: talents && talents.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }) }))] }), _jsx(DrawerForm, { tabName: tabName, useForm: ListFormik, isOpen: isOpenList, handleClose: setIsOpenList, onSubmit: ListFormik.handleSubmit, component: BlacklistForm, idForEdit: '' })] }));
};
export default TalentIndex;
