var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormatParam } from '@src/app/helper';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'use-debounce';
var API_BASE_URL = process.env.NEXUS_API_URL;
var API_URL = {
    GET_COUNTRIES_LIST: "".concat(API_BASE_URL, "/lookup/countries"),
    GET_PROVINCES_LIST: "".concat(API_BASE_URL, "/lookup/provinces"),
    GET_CITIES_LIST: "".concat(API_BASE_URL, "/lookup/cities"),
    GET_RELIGIONS_LIST: "".concat(API_BASE_URL, "/lookup/religions"),
    GET_CURRENCIES_LIST: "".concat(API_BASE_URL, "/lookup/currencies"),
    GET_COURIERS_LIST: "".concat(API_BASE_URL, "/lookup/couriers"),
    GET_BANK_TRANSFER_BY: "".concat(API_BASE_URL, "/lookup/bank-transfer-bys"),
    GET_BRIEFING_TYPES: "".concat(API_BASE_URL, "/lookup/briefing-types"),
    GET_BRIEFING_TYPES_DETAIL: "".concat(API_BASE_URL, "/lookup/briefing-detail-types"),
    GET_COMPANIES_LIST: "".concat(API_BASE_URL, "/companies"),
    GET_EMPLOYEES_LIST: "".concat(API_BASE_URL, "/employees"),
};
export function fetchCountries() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_COUNTRIES_LIST)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useCountries() {
    var _this = this;
    var _a = useQuery(['countries'], function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchCountries()];
    }); }); }), countries = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        countries: countries,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchProvinces() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_PROVINCES_LIST)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useProvinces() {
    var _a = useQuery(['provinces'], function () { return fetchProvinces(); }), provinces = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        provinces: provinces,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchCities() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_CITIES_LIST)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useCities() {
    var _a = useQuery(['cities'], function () { return fetchCities(); }), cities = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        cities: cities,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchReligions() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_RELIGIONS_LIST)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useReligions() {
    var _a = useQuery(['religions'], function () { return fetchReligions(); }), religions = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        religions: religions,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchCurrencies() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_CURRENCIES_LIST)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useCurrencies() {
    var _a = useQuery(['currencies'], function () { return fetchCurrencies(); }), currencies = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        currencies: currencies,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchCouriers() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_COURIERS_LIST)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useCouriers() {
    var _a = useQuery(['couriers'], function () { return fetchCouriers(); }), couriers = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        couriers: couriers,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchBankTransfer(param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var queryString, url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    if (queryString == undefined) {
                        queryString = '';
                    }
                    url = "".concat(API_URL.GET_BANK_TRANSFER_BY);
                    return [4 /*yield*/, axios.get("".concat(url, "?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useBankTransfer(param) {
    var _a = useQuery(['bank-transfer'], function () { return fetchBankTransfer(param); }), bankTransfer = _a.data, isLoading = _a.isLoading, error = _a.error, refetch = _a.refetch;
    return {
        bankTransfer: bankTransfer,
        isLoading: isLoading,
        error: error,
        refetch: refetch,
    };
}
export function fetchBriefingTypes() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_BRIEFING_TYPES)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useBriefingTypes() {
    var _a = useQuery(['briefing-types'], function () { return fetchBriefingTypes(); }), briefingTypes = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        briefingTypes: briefingTypes,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchBriefingDetailTypes() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_BRIEFING_TYPES_DETAIL)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function useBriefingDetailTypes() {
    var _a = useQuery(['briefing-detail-types'], function () { return fetchBriefingDetailTypes(); }), briefingDetailTypes = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        briefingDetailTypes: briefingDetailTypes,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchCompanies() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(API_URL.GET_COMPANIES_LIST)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function useCompanies() {
    var _this = this;
    var _a = useQuery(['companies'], function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchCompanies()];
    }); }); }), companies = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        companies: companies,
        isLoading: isLoading,
        error: error,
    };
}
export function fetchEmployees(param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var queryString, url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    if (queryString == undefined) {
                        queryString = '';
                    }
                    url = "".concat(API_URL.GET_EMPLOYEES_LIST);
                    return [4 /*yield*/, axios.get("".concat(url, "?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function useEmployees(param, queryOptions) {
    var _this = this;
    var queryClient = useQueryClient();
    var debouncedFilter = useDebounce(param, 500)[0];
    var _a = useQuery(['employees', debouncedFilter], function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchEmployees(debouncedFilter)];
    }); }); }, __assign({}, queryOptions)), employees = _a.data, isLoading = _a.isLoading, refetch = _a.refetch, error = _a.error;
    return {
        employees: employees,
        isLoading: isLoading,
        refetch: refetch,
        error: error,
    };
}
