var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import { KTSVG } from '@src/_metronic/helpers';
import { RoleList, userInformation } from '@src/app/helper';
import { BargainType } from '@src/app/helper/BargainType.enum';
import { Fragment, useEffect } from 'react';
var KerjasamaActionDropdown = function (_a) {
    var record = _a.record, _b = _a.isUpdate, isUpdate = _b === void 0 ? true : _b, _c = _a.isReject, isReject = _c === void 0 ? true : _c, _d = _a.isTemplateInvoice, isTemplateInvoice = _d === void 0 ? true : _d, _e = _a.isTemplateSPK, isTemplateSPK = _e === void 0 ? true : _e, _f = _a.isTemplateSP, isTemplateSP = _f === void 0 ? true : _f, _g = _a.isTemplateSK, isTemplateSK = _g === void 0 ? true : _g, _h = _a.isProcess, isProcess = _h === void 0 ? true : _h, _j = _a.isApprove, isApprove = _j === void 0 ? true : _j, _k = _a.updateItem, updateItem = _k === void 0 ? function () { } : _k, _l = _a.handleTemplateInvoice, handleTemplateInvoice = _l === void 0 ? function () { } : _l, _m = _a.handleTemplateSPK, handleTemplateSPK = _m === void 0 ? function () { } : _m, _o = _a.handleTemplateSP, handleTemplateSP = _o === void 0 ? function () { } : _o, _p = _a.handleTemplateSK, handleTemplateSK = _p === void 0 ? function () { } : _p, _q = _a.processItem, processItem = _q === void 0 ? function () { } : _q, _r = _a.rejectItem, rejectItem = _r === void 0 ? function () { } : _r, _s = _a.approveItem, approveItem = _s === void 0 ? function () { } : _s, _t = _a.showHistory, showHistory = _t === void 0 ? function () { } : _t, bargainType = _a.bargainType, isCompleted = _a.isCompleted, jobPubId = _a.jobPubId, _u = _a.handleComplete, handleComplete = _u === void 0 ? function () { } : _u, isApprovedLeader = _a.isApprovedLeader;
    var role = JSON.parse(userInformation).roles[0];
    useEffect(function () {
        MenuComponent.reinitialization();
    }, []);
    return (_jsxs(Fragment, { children: [_jsxs("span", __assign({ className: 'btn btn-light btn-active-light-primary btn-sm', "data-kt-menu-trigger": 'click', "data-kt-menu-placement": 'bottom-end' }, { children: ["Actions", _jsx(KTSVG, { path: '/media/icons/duotune/arrows/arr072.svg', className: 'svg-icon-5 m-0' })] })), _jsxs("div", __assign({ className: 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-225px py-4', "data-kt-menu": 'true' }, { children: [(role === RoleList.CampaignExecutive || role === RoleList.SpvCampaignExecutive) && isUpdate && !isApprovedLeader ? ( //!isJobCompleted
                    _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                                updateItem(record);
                            } }, { children: "Ubah" })) }))) : (role === RoleList.SpvCampaignExecutive && !isApprovedLeader) ? ( //isApprovedLeader
                    _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                                updateItem(record);
                            } }, { children: "Ubah" })) }))) : undefined, role === RoleList.CampaignExecutive && isProcess && !isApprovedLeader && ( //!isJobCompleted
                    _jsx(_Fragment, { children: (bargainType == BargainType.NEGO ||
                            bargainType == BargainType.NEGOSPV ||
                            bargainType == BargainType.APPROVED) && (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'delete_row', onClick: function () {
                                        processItem && processItem(record);
                                    } }, { children: "Proses" })) })) })) })), role === RoleList.SpvCampaignExecutive && isApprove && (_jsx(_Fragment, { children: bargainType != BargainType.CANCEL && (_jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'delete_row', onClick: function () {
                                    approveItem && approveItem(record);
                                } }, { children: "Setuju" })) }))) })), (role === RoleList.CampaignExecutive || role === RoleList.SpvCampaignExecutive) && isReject && !isApprovedLeader ? ( //!isJobCompleted
                    _jsx(Fragment, { children: _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'delete_row', onClick: function () {
                                    rejectItem && rejectItem(record);
                                } }, { children: "Batal" })) })) })) : undefined, role === RoleList.CampaignExecutive || role === RoleList.SpvCampaignExecutive ? (_jsx(Fragment, { children: _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                                    showHistory();
                                } }, { children: "History" })) })) })) : undefined, role === RoleList.SpvCampaignExecutive && isApprovedLeader && !isCompleted && jobPubId ? (_jsx(Fragment, { children: _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                                    isCompleted !== undefined && handleComplete(jobPubId, isCompleted);
                                } }, { children: "Mark as Complete" })) })) })) : undefined, role === RoleList.SpvCampaignExecutive && isApprovedLeader && isCompleted === true && jobPubId ? (_jsx(Fragment, { children: _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                                    handleComplete(jobPubId, isCompleted);
                                } }, { children: "Mark as Uncomplete" })) })) })) : undefined, role === RoleList.Audit ? (_jsxs(Fragment, { children: [_jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'delete_row', onClick: function () {
                                        approveItem(record);
                                    } }, { children: "Setuju" })) })), _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'delete_row', onClick: function () {
                                        rejectItem(record);
                                    } }, { children: "Tolak" })) })), _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                                        showHistory();
                                    } }, { children: "History" })) }))] })) : undefined, role === RoleList.CampaignExecutive && (_jsx(_Fragment, { children: (bargainType == BargainType.NEGO ||
                            bargainType == BargainType.NEGOSPV ||
                            bargainType == BargainType.APPROVED) && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'invoice_row', onClick: function () {
                                            handleTemplateInvoice && handleTemplateInvoice(record);
                                        } }, { children: "Generate Invoice" })) })), isTemplateSPK && (_jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'spk_row', onClick: function () {
                                            handleTemplateSPK && handleTemplateSPK(record);
                                        } }, { children: "Generate SPK" })) }))), _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'sk_row', onClick: function () {
                                            handleTemplateSK && handleTemplateSK(record);
                                        } }, { children: "Generate Surat Kuasa" })) })), _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', "data-kt-users-table-filter": 'sp_row', onClick: function () {
                                            handleTemplateSP && handleTemplateSP(record);
                                        } }, { children: "Generate Surat Pernyataan" })) }))] })) }))] }))] }));
};
export default KerjasamaActionDropdown;
