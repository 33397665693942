var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputFormik from '@src/components/Atoms/Formik/Input/InputFormik';
import { useJob } from '@src/app/pages/JobPage/JobHooks/Job.hooks';
import { toast } from 'react-toastify';
import { useMemo, useState } from 'react';
import { FormatYearMonthDate } from '@src/app/helper';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Divider, TextField } from '@mui/material';
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
import { useCompanies } from '@src/app/pages/LookupPage/LookupHooks/Lookup.hooks';
var TemplateSKchema = Yup.object().shape({
    talentPlaceOfBirth: Yup.string().required('Field belum diisi'),
    managerPlaceOfBirth: Yup.string().required('Field belum diisi'),
    managerBirthDate: Yup.string().required('Field belum diisi'),
    managerDivision: Yup.string().required('Field belum diisi'),
    date: Yup.string().required('Field belum diisi'),
    companyPubId: Yup.string().required('Field belum diisi'),
});
var TemplateSK = function (_a) {
    var isOpen = _a.isOpen, handleClose = _a.handleClose, id = _a.id, talentId = _a.talentId, record = _a.record;
    //date 
    var _b = useState(dayjs().format('YYYY-MM-DD').toString()), date = _b[0], setDate = _b[1];
    var _c = useState(dayjs().format('YYYY-MM-DD').toString()), managerBirthDate = _c[0], setManagerBirthDate = _c[1];
    var onChangeDate = function (e, name) {
        var event = { target: { name: '', value: null } };
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        TemplateSKFormik.setFieldValue(name, event.target.value);
        switch (name) {
            case 'managerBirthDate':
                setManagerBirthDate(event.target.value);
                break;
            default:
                return setDate(event.target.value);
        }
    };
    //company
    var _d = useState([]), selectedCompany = _d[0], setSelectedCompany = _d[1];
    var companies = useCompanies().companies;
    var optionCompanies = companies &&
        companies.data &&
        companies.data.length > 0 &&
        companies.data.map(function (company) { return ({
            label: company.name,
            value: company.pubId,
        }); });
    var onChangeCompany = function (e) {
        setSelectedCompany(e);
        TemplateSKFormik.setFieldValue('companyPubId', e.value);
    };
    //template
    var TemplateSPKValues = {
        companyPubId: '',
        talentPlaceOfBirth: '',
        managerName: '',
        managerPlaceOfBirth: '',
        managerBirthDate: managerBirthDate ? managerBirthDate : '',
        managerDivision: '',
        managerKTPNumber: '',
        managerKTPAddress: '',
        date: date ? date : '',
    };
    var templateSKJob = useJob().templateSKJob;
    var TemplateSKFormik = useFormik({
        initialValues: TemplateSPKValues,
        enableReinitialize: false,
        validationSchema: TemplateSKchema,
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var formValues, file, FILE_API_BASE_URL, url, e_1, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            formValues = __assign({ id: id }, values);
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, templateSKJob(formValues)];
                        case 2:
                            file = _b.sent();
                            handleClose();
                            FILE_API_BASE_URL = process.env.NEXUS_FILE_API_URL;
                            url = "".concat(FILE_API_BASE_URL, "/").concat(file.pubId, "/download");
                            window.open(url, '_blank', 'noreferrer');
                            return [3 /*break*/, 5];
                        case 3:
                            e_1 = _b.sent();
                            error = e_1.response.message;
                            toast.error(error);
                            return [3 /*break*/, 5];
                        case 4:
                            resetForm();
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var isInputOK = useMemo(function () {
        return TemplateSKFormik.values.companyPubId !== '' &&
            TemplateSKFormik.values.talentPlaceOfBirth !== '' &&
            // TemplateSKFormik.values.managerName !== '' &&
            TemplateSKFormik.values.managerPlaceOfBirth !== '' &&
            TemplateSKFormik.values.managerBirthDate !== '' &&
            TemplateSKFormik.values.managerDivision !== '' &&
            // TemplateSKFormik.values.managerKTPNumber !== '' &&
            // TemplateSKFormik.values.managerKTPAddress !== '' &&
            TemplateSKFormik.values.date !== '';
    }, [TemplateSKFormik.values]);
    return (_jsx(DrawerComponent, __assign({ open: isOpen, size: 'LG', handleClose: handleClose, title: 'Template Surat Kuasa' }, { children: _jsxs("form", __assign({ className: 'form w-100', onSubmit: TemplateSKFormik.handleSubmit, noValidate: true, id: 'template-SPK-form' }, { children: [_jsx("div", __assign({ className: 'my-5' }, { children: _jsxs(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: [_jsxs("label", __assign({ className: 'form-label fs-6 fw-bolder required text-dark' }, { children: ["Date", ' '] })), _jsx(DatePicker, { value: date, inputFormat: 'DD/MM/YYYY', dayOfWeekFormatter: function (day) { return day; }, onChange: function (e) {
                                    onChangeDate(e, 'date');
                                }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { className: 'product-datepicker form-control' }))); } })] })) })), optionCompanies && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx("div", __assign({ className: 'my-5' }, { children: _jsx(MultiSelectFormik, { name: 'companyPubId', label: 'Company', formik: TemplateSKFormik, isMulti: false, isRequired: true, options: optionCompanies, value: selectedCompany, onchange: onChangeCompany }) }))] })), _jsx(Divider, {}), _jsx("div", __assign({ className: 'my-5' }, { children: _jsx(InputFormik, { label: 'Tempat Lahir Talent', type: 'text', placeholder: 'Tempat Lahir Talent', name: 'talentPlaceOfBirth', formik: TemplateSKFormik, isRequired: true }) })), _jsx(Divider, {}), _jsx("div", __assign({ className: 'my-5' }, { children: _jsx(InputFormik, { label: 'Divisi Manajer', type: 'text', placeholder: 'Divisi Manajer', name: 'managerDivision', formik: TemplateSKFormik, isRequired: true }) })), _jsx("div", __assign({ className: 'my-5' }, { children: _jsx(InputFormik, { label: 'Tempat Lahir Manajer', type: 'text', placeholder: 'Tempat Lahir Manajer', name: 'managerPlaceOfBirth', formik: TemplateSKFormik, isRequired: true }) })), _jsx("div", __assign({ className: 'my-5' }, { children: _jsxs(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: [_jsxs("label", __assign({ className: 'form-label fs-6 fw-bolder required text-dark' }, { children: ["Tanggal Lahir Manajer", ' '] })), _jsx(DatePicker, { value: managerBirthDate, inputFormat: 'DD/MM/YYYY', dayOfWeekFormatter: function (day) { return day; }, onChange: function (e) {
                                    onChangeDate(e, 'managerBirthDate');
                                }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { className: 'product-datepicker form-control' }))); } })] })) })), _jsx("div", __assign({ className: 'my-5' }, { children: _jsxs("div", __assign({ style: { textAlign: 'right' } }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary btn-sm', onClick: handleClose, disabled: TemplateSKFormik.isSubmitting, style: { marginRight: '1rem' } }, { children: _jsx("span", __assign({ className: 'indicator-label' }, { children: "Batal" })) })), _jsxs("button", __assign({ type: 'submit', className: 'btn btn-primary btn-sm', disabled: TemplateSKFormik.isSubmitting }, { children: [!TemplateSKFormik.isSubmitting && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Simpan" })), TemplateSKFormik.isSubmitting && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Mohon tunggu...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] }))] })) }))] })) })));
};
export default TemplateSK;
