var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, Fragment, useEffect, createContext } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard, KTSVG } from '@src/_metronic/helpers';
import { initialSendProductNonTalentValues, } from './SendProductNonTalent.types';
import DataTable from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { FormatDateTime, paginationOptions, MultiDimensionalFilter, userInformation, optionRekapList, RoleList, FormatYearMonthDate, } from '@src/app/helper';
import Input from '@src/components/Atoms/Input/Input';
import Select from '@src/components/Atoms/Select/Select';
import '@src/app/styles/datatable.css';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Date from '@src/components/Atoms/Date/Date';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useSocialMedia } from '../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useTeams } from '../../TeamPage/TeamHooks/Team.hooks';
import { useDivisions } from '../../DivisionPage/DivisionHooks/Division.hooks';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import dayjs from 'dayjs';
import SendProductFormNonTalent from '@src/components/Atoms/Drawer/SendProductFormDrawer/SendProductFormNonTalent';
import { useSendProductNonTalentById, useSendProductsNonTalent, } from '../SendProductHooks/SendProductsNonTalent.hooks';
import { uploadFile } from '../../JobPage/Job/Job.requests';
import RecordNonTalent from '@src/components/molecules/Record/NonTalent';
import SendProductActionDropdown from '@src/components/Atoms/ActionDropdown/SendProductActionDropdown';
import SendProductDetail from '../SendProductDetail/SendProductDetail';
import RejectForm from '@src/components/Atoms/Drawer/JobDetailFormDrawer/RejectForm';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import { useSendProductTracking } from '../SendProductHooks/SendProduct.hooks';
import SendProductDetailTracking from '@src/app/pages/SendProductPage/SendProductDetail/SendProductDetailTracking';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Send Product',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var SendProductFormSchema = Yup.object().shape({
    recipientName: Yup.string().required('Nama penerima tidak boleh kosong'),
    phoneNumber: Yup.string().required('Nomor telepon tidak boleh kosong'),
    address: Yup.string().required('Alamat tidak boleh kosong'),
    cityPubId: Yup.string().required('Kota tidak boleh kosong'),
    courierPubId: Yup.string().required('Kurir tidak boleh kosong'),
    sendProduct: Yup.object().shape({
        products: Yup.array()
            .of(Yup.object().shape({
            productPubId: Yup.string().required('Product is required'),
            productCategoryPubId: Yup.string().required('Product Category is required'),
            qty: Yup.string().required('Product qty is required'),
        }))
            .min(1, 'Minimum 1 Item'),
    }),
    note: Yup.string(),
});
var EditSendProductFormSchema = Yup.object().shape({
    recipientName: Yup.string().required('Nama penerima tidak boleh kosong'),
    phoneNumber: Yup.string().required('Nomor telepon tidak boleh kosong'),
    address: Yup.string().required('Alamat tidak boleh kosong'),
    cityPubId: Yup.string().required('Kota tidak boleh kosong'),
    courierPubId: Yup.string().required('Kurir tidak boleh kosong'),
    sendProduct: Yup.object().shape({
        products: Yup.array()
            .of(Yup.object().shape({
            productPubId: Yup.string().required('Product is required'),
            productCategoryPubId: Yup.string().required('Product Category is required'),
            qty: Yup.string().required('Product qty is required'),
        }))
            .min(1, 'Minimum 1 Item'),
    }),
    note: Yup.string(),
});
var RejectFormSchema = Yup.object().shape({
    reason: Yup.string().required('Field belum diisi'),
});
var SendProductNonTalentIndex = function (_a) {
    var _b, _c;
    var _d = _a.disableBreadcrumbs, disableBreadcrumbs = _d === void 0 ? false : _d;
    var navigate = useNavigate();
    var intl = useIntl();
    var id = useParams().id;
    var role = JSON.parse(userInformation).roles[0];
    var userData = JSON.parse(userInformation);
    var _e = useState(), selectedRecord = _e[0], setSelectedRecord = _e[1];
    //initialize filter
    var _f = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        orderBy: "[[\"".concat(optionRekapList[0].value, "\", \"asc\"]]"),
    }), filter = _f[0], setFilter = _f[1];
    //getdata
    var _g = useState([
        {
            productPubId: '',
            productCategoryPubId: '',
            qty: 1,
        },
    ]), newVar = _g[0], setNewVar = _g[1];
    var _h = useState('Product'), tabName = _h[0], setTabName = _h[1];
    var _j = useState(false), isEdit = _j[0], setIsEdit = _j[1];
    var _k = useState(undefined), editId = _k[0], setEditId = _k[1];
    var _l = useState(undefined), talentId = _l[0], setTalentId = _l[1];
    var _m = useState(''), trackingNumber = _m[0], setTrackingNumber = _m[1];
    var _o = useState(false), enabledSendProduct = _o[0], setEnabledSendProduct = _o[1];
    var _p = useState(false), isOpenDetailTracking = _p[0], setIsOpenDetailTracking = _p[1];
    var sendProductTracking = useSendProductTracking(trackingNumber, enabledSendProduct).data;
    var _q = useState(false), isOpenAdd = _q[0], setIsOpenAdd = _q[1];
    var _r = useState(false), isOpenDetail = _r[0], setIsOpenDetail = _r[1];
    var _s = useState(false), isOpenReject = _s[0], setIsOpenReject = _s[1];
    var _t = useSendProductsNonTalent(filter), SendProductNonTalent = _t.SendProductNonTalent, isLoading = _t.isLoading;
    var _u = useSendProductsNonTalent(), createSendProduct = _u.createSendProduct, updateSendProduct = _u.updateSendProduct, sentSendProduct = _u.sentSendProduct, approveSendProduct = _u.approveSendProduct, rejectSendProduct = _u.rejectSendProduct;
    var sendProduct = useSendProductNonTalentById(editId).data;
    var sendProductValues = {
        details: [],
        recipientName: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.recipientName,
        address: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.address,
        cityPubId: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.city.pubId,
        phoneNumber: '-',
        courierPubId: (_b = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.courier.pubId) !== null && _b !== void 0 ? _b : '',
        sendProduct: {
            products: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.details.map(function (obj, key) { return ({
                productPubId: obj.product.pubId,
                productCategoryPubId: obj.product.productCategory.pubId,
                qty: obj.qty,
            }); }),
        },
        products: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.details.map(function (obj, key) { return ({
            productPubId: obj.product.pubId,
            productCategoryPubId: obj.product.productCategory.pubId,
            qty: obj.qty,
        }); }),
        note: (_c = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.note) !== null && _c !== void 0 ? _c : '',
        proofTransferFilePubId: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.proofTransferUrl,
    };
    //fiters
    var socialMedia = useSocialMedia().socialMedia;
    var team = useTeams().team;
    var divisions = useDivisions().divisions;
    if (role === RoleList.CampaignExecutive) {
        var team_1 = { data: {} };
        socialMedia = JSON.parse(userInformation).socialMedias;
        divisions = JSON.parse(userInformation).divisions;
        team_1.data = JSON.parse(userInformation).teams;
    }
    var optionSocialMedia = socialMedia &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    var optionDivision = divisions &&
        divisions.map(function (division) { return ({
            name: "".concat(division.name, " - ").concat(division.socialMedia.name),
            value: division.pubId,
        }); });
    var optionTeam = team &&
        team.data &&
        team.data.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); });
    var _v = useState(dayjs().format('YYYY-MM-DD').toString()), date = _v[0], setDate = _v[1];
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'date';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    //pagination
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    //search & filters
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    //add
    var addItem = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectedRecord(undefined);
            setEditId(undefined);
            setTalentId(undefined);
            setIsOpenAdd(true);
            return [2 /*return*/];
        });
    }); };
    //edit
    var editItem = function (record) {
        setSelectedRecord(record);
        setIsEdit(true);
        setEditId(record.pubId);
        setTalentId(record.pubId);
        setIsOpenAdd(true);
    };
    var handleDetail = function (record) {
        setIsOpenDetail(true);
        setEditId(record.pubId);
    };
    var handleSentProduct = function (record) {
        swal({
            title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
            text: '',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, sentSendProduct(record.pubId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        toast.error(error.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    var handleApproveProduct = function (record) {
        swal({
            title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
            text: '',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var e_2, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, approveSendProduct(record.pubId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        error = e_2.response.data;
                        toast.error(error.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    var handleRejectProduct = function (record) {
        setIsOpenReject(true);
        setEditId(record.pubId);
    };
    var SendProductFormik = useFormik({
        initialValues: sendProduct ? sendProductValues : initialSendProductNonTalentValues,
        enableReinitialize: true,
        validationSchema: editId ? EditSendProductFormSchema : SendProductFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var recipientName, phoneNumber, address, cityPubId, courierPubId, sendProduct, note, proofTransferFilePubId, transferProof, products, formData, e_3, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        recipientName = values.recipientName, phoneNumber = values.phoneNumber, address = values.address, cityPubId = values.cityPubId, courierPubId = values.courierPubId, sendProduct = values.sendProduct, note = values.note, proofTransferFilePubId = values.proofTransferFilePubId;
                        if (!(proofTransferFilePubId &&
                            proofTransferFilePubId.length > 0 &&
                            typeof proofTransferFilePubId !== 'string')) return [3 /*break*/, 2];
                        return [4 /*yield*/, uploadFile({
                                File: typeof proofTransferFilePubId !== 'string'
                                    ? proofTransferFilePubId[0]
                                    : proofTransferFilePubId,
                                TenantPubId: userData.tenant.pubId,
                                Type: 'NonTalent ',
                            })];
                    case 1:
                        transferProof = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 7, 8, 9]);
                        products = sendProduct.products;
                        formData = {
                            id: editId !== null && editId !== void 0 ? editId : '',
                            products: products,
                            recipientName: recipientName,
                            phoneNumber: phoneNumber,
                            address: address,
                            cityPubId: cityPubId,
                            courierPubId: courierPubId,
                            note: note,
                            proofTransferFilePubId: proofTransferFilePubId && proofTransferFilePubId.length > 0
                                ? transferProof === null || transferProof === void 0 ? void 0 : transferProof.data.data.pubId
                                : '',
                        };
                        if (!isEdit) return [3 /*break*/, 4];
                        return [4 /*yield*/, updateSendProduct(formData)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, createSendProduct(formData)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 9];
                    case 7:
                        e_3 = _a.sent();
                        error = e_3.response.data;
                        return [3 /*break*/, 9];
                    case 8:
                        setIsOpenAdd(false);
                        setIsEdit(false);
                        setEditId(undefined);
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        }); },
    });
    var RejectFormik = useFormik({
        initialValues: {
            reason: '',
        },
        enableReinitialize: true,
        validationSchema: RejectFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var e_4, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, rejectSendProduct(__assign({ id: editId }, values))];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        e_4 = _a.sent();
                        error = e_4.response.data;
                        return [3 /*break*/, 4];
                    case 3:
                        setIsOpenReject(false);
                        setEditId(undefined);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    });
    //gototracking
    var handleTracking = function (record) {
        setTrackingNumber(record.orderNumber);
        setEnabledSendProduct(true);
        setIsOpenDetailTracking(true);
    };
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    var columns = [
        {
            name: 'No Pesanan',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'mt-2' }, { children: _jsx("a", __assign({ className: 'goto', onClick: function () { return handleTracking(record); } }, { children: record.orderNumber })) })));
            },
            // selector: (row) => row.orderNumber,
            sortable: true,
            width: '170px',
        },
        {
            name: 'Nama',
            cell: function (record) { return (_jsx(RecordNonTalent, { recipientName: record.recipientName, phoneNumber: record.phoneNumber, pubId: record.pubId, city: record.city.name, proofTransferUrl: record.proofTransferUrl, note: record.note, onClick: gotoDetail })); },
            sortable: true,
        },
        {
            name: 'Alamat',
            cell: function (record) {
                return (_jsx("div", __assign({ className: '' }, { children: _jsxs("p", { children: [record.address, ", ", record.city.name, ", ", record.city.province] }) })));
            },
            sortable: true,
        },
        {
            name: 'Kurir',
            selector: function (row) { return row.courier.name; },
            sortable: true,
        },
        {
            name: 'Barang',
            cell: function (record) {
                return record.details.map(function (obj, key) {
                    return (_jsx("div", { children: _jsxs("p", __assign({ className: 'createdby mb-2' }, { children: ["[", obj.product.productCategory.name, "] ", obj.product.name, ", ", obj.qty, " pc(s)"] })) }, key));
                });
            },
            sortable: true,
        },
        {
            name: 'Approved',
            cell: function (record) {
                return (_jsx("div", { children: record.isApproved ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.approvedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.approvedOn) }))] }))) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Rejected',
            cell: function (record) {
                return (_jsx("div", { children: record.isRejected ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.rejectedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.rejectedOn) }))] }))) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Sent',
            cell: function (record) {
                return (_jsx("div", { children: record.isSent ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.sentBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.sentOn) }))] }))) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record, isShow: false }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsx(SendProductActionDropdown, { handleEdit: editItem, handleApprove: handleApproveProduct, handleReject: handleRejectProduct, handleSent: handleSentProduct, handleDetail: handleDetail, record: record, id: record.pubId }));
            },
        },
    ];
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [!disableBreadcrumbs ? (_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Send Product Non Talent" }))) : undefined, _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsxs("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: [_jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search by Name', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                    handleFilterChange(e);
                                                } }) })), role !== RoleList.Admin ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: optionSocialMedia && optionSocialMedia.length > 0 && (_jsx(Select, { name: 'job.division.socialMedia.pubId', onChange: function (e) {
                                                            handleMultiDimensionalFilterChange(e);
                                                        }, placeholder: 'Filter By Social Media', optionList: optionSocialMedia })) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionDivision && optionDivision.length > 0 && (_jsx(Select, { name: 'job.division.pubId', onChange: function (e) {
                                                            handleMultiDimensionalFilterChange(e);
                                                        }, placeholder: 'Filter By Division', optionList: optionDivision })) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionTeam && optionTeam.length > 0 && (_jsx(Select, { name: 'job.team.pubId', onChange: function (e) {
                                                            handleMultiDimensionalFilterChange(e);
                                                        }, placeholder: 'Filter By Teams', optionList: optionTeam })) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'rekap' //change
                                                        , onChange: function (e) {
                                                            setFilter(__assign(__assign({}, filter), { orderBy: "[[\"".concat(e.target.value, "\", \"asc\"]]") }));
                                                        }, optionList: optionRekapList, defaultValue: optionRekapList[0].value }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Date, { date: date, handleonchange: onChangeDate }) }))] })) : undefined] })), role === RoleList.Reqsi ? (_jsx("div", __assign({ className: 'card-toolbar justify-content-end' }, { children: _jsx("div", __assign({ className: 'd-flex justify-content-end', "data-kt-talentcategory-table-toolbar": 'base' }, { children: _jsx("div", __assign({ className: 'me-3' }, { children: _jsxs("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary', onClick: addItem }, { children: [_jsx(KTSVG, { path: '/media/icons/duotune/arrows/arr075.svg', className: 'svg-icon-2' }), "Add Product"] })) })) })) }))) : undefined] })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: !isLoading && SendProductNonTalent && SendProductNonTalent.data.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, data: SendProductNonTalent.data, noHeader: true, progressPending: isLoading, pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: SendProductNonTalent.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange })) : (_jsx(NotFound, {})) }))] }), _jsx(SendProductContextProvider, __assign({ currentData: selectedRecord }, { children: _jsx(SendProductFormNonTalent, { tabName: tabName, useForm: SendProductFormik, isOpen: isOpenAdd, handleClose: function () {
                        setIsOpenAdd(false);
                        setIsEdit(false);
                        setEditId(false);
                    }, onSubmit: SendProductFormik.handleSubmit, idForEdit: talentId, size: 'LG' }) })), _jsx(SendProductDetail, { isOpen: isOpenDetail, handleClose: setIsOpenDetail, record: sendProduct }), _jsx(SendProductDetailTracking, { isOpen: isOpenDetailTracking, handleClose: setIsOpenDetailTracking, record: sendProductTracking }), _jsx(DrawerForm, { tabName: 'Tolak Pengiriman', useForm: RejectFormik, isOpen: isOpenReject, handleClose: function () {
                    setIsOpenReject(false);
                    setEditId(undefined);
                }, onSubmit: RejectFormik.handleSubmit, component: RejectForm, idForEdit: '', name: 'reason' })] }));
};
export var SendProductContext = createContext({});
function SendProductContextProvider(_a) {
    var children = _a.children, currentData = _a.currentData;
    return _jsx(SendProductContext.Provider, __assign({ value: currentData }, { children: children }));
}
export default SendProductNonTalentIndex;
