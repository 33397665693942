var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styles from './Record.module.scss';
var RecordNonTalent = function (_a) {
    var pubId = _a.pubId, recipientName = _a.recipientName, note = _a.note, city = _a.city, proofTransferUrl = _a.proofTransferUrl, phoneNumber = _a.phoneNumber, onClick = _a.onClick;
    return (_jsxs("div", { children: [_jsxs("div", { children: [_jsxs("p", __assign({ className: styles['record-NonTalent__name'], onClick: function () { return onClick(pubId); } }, { children: ["Recipient Name : ", recipientName] })), _jsx("p", { children: phoneNumber !== null && phoneNumber !== void 0 ? phoneNumber : '' }), _jsxs("p", { children: ["Note : ", note !== null && note !== void 0 ? note : '-'] }), _jsxs("p", { children: ["Kota : ", city !== null && city !== void 0 ? city : '-'] })] }), _jsx("div", { children: _jsx("a", __assign({ href: proofTransferUrl, target: '_blank', rel: 'noreferrer' }, { children: "View Transfer File" })) })] }));
};
export default RecordNonTalent;
