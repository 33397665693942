export var initialTalentDetailValues = {
    name: '',
    cpName: '',
    cpPhoneNumber: '',
    cpEmail: '',
    cpOther: '',
    note: '',
    countryPubId: '',
    // isTalentBA: false,
    profiles: [
        {
            socialMediaPubId: '',
            profileName: '',
            profileLink: '',
            isBlasted: true,
        },
    ],
    categoryPubIds: [''],
    // isBlackListed: true,
    // recordStatus: '',
    tenantPubId: '',
    currencyPubId: '',
    religionPubId: '',
    dob: '',
    isAffiliated: false,
    // jobTypePubId: '',
    isIdcard: true,
    idCardType: 'ktp',
    ktpNumber: '',
    ktpName: '',
    ktpAddress: '',
    ktpFile: [],
    passportAddress: '',
    passportName: '',
    passportNumber: '',
    passportUrl: '',
    npwpNumber: '',
    npwpName: '',
    npwpFile: [],
    managerKtpNumber: '',
    managerKtpName: '',
    managerKtpAddress: '',
    managerKtpFile: [],
    managerNpwpNumber: '',
    managerNpwpName: '',
    managerNpwpFile: [],
};
export var TalentDetailValues = {
    name: '',
    cpName: '',
    cpPhoneNumber: '',
    cpEmail: '',
    cpOther: '',
    note: '',
    country: {
        name: '',
        pubId: '',
    },
    isTalentBA: false,
    profiles: [
        {
            socialMediaPubId: '',
            profileName: '',
            profileLink: '',
            isBlasted: false,
        },
    ],
    isBlacklisted: false,
    blacklistReason: '',
    blacklistedBy: '',
    blacklistedOn: 0,
    isAffiliated: false,
    affiliatedBy: '',
    affiliatedOn: 0,
    recordStatus: '',
    tenantPubId: '',
    currency: {
        name: '',
        pubId: '',
        symbol: '',
    },
    religion: {
        name: '',
        pubId: '',
    },
    dob: '',
    jobTypePubId: '',
    isIdcard: true,
    ktpNumber: '',
    ktpName: '',
    ktpAddress: '',
    passportAddress: '',
    passportName: '',
    passportNumber: '',
    passportUrl: '',
    npwpNumber: '',
    npwpName: '',
    managerKtpNumber: '',
    managerKtpName: '',
    managerKtpAddress: '',
    managerNpwpNumber: '',
    managerNpwpName: '',
    categories: {
        name: '',
        pubId: '',
    },
    countryPubId: '',
    categoryPubIds: [''],
    currencyPubId: '',
    religionPubId: '',
    talentTeams: [],
    talentStatus: '',
    tenant: {
        pubId: '',
        name: '',
    },
};
